import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import WEAIcon from "../../assets/wea-icon.png";
import BoxAespa from "../../assets/box_aespa.png";
import "./index.scss";
import useScript from "../../hooks/useScript";
import Footer from "../../components/Footer";
import checkIcon from "../../assets/check.png";
import backArrowIcon from "../../assets/arrow.png";
import PlaylistCover from "../../assets/playlistCover.jpg";

const ArtistPage = ({ data }) => {
  const location = useLocation();

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [artistData] = useState(
    data.find((artist) => artist.url === location.pathname.slice(1))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const status = useScript(
    "https://wct.live/app/sdk/v2/platform.js?campaign=" + artistData.formId
  );

  useEffect(() => {
    console.log("Ouai ?");
    if (status === "ready") {
      const connect_button = new window.WMGConnect(".wmg-button", {
        opt_in_form_target: "#formulaire",
        opt_in_thank_you_enabled: false,
      });

      connect_button.setCallback(function (connect_data) {
        setIsSubscribed(true);

        window.scrollTo(0, 0);
      });
    }
  }, [status]);

  useEffect(() => {
    window.digitalData.page.pageInfo.pageName =
      "WMF Concours:Kpop Advent Sweeps:Landing:" + artistData.title;
    window.digitalData.page.category.primaryCategory =
      "WMF Concours:Artist Page";
    window.digitalData.page.category.pageType =
      "artist page:" + artistData.title;

    document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
  }, []);

  return (
    <main className="artistPage">
      <Link to="/" className="backHome">
        <img src={backArrowIcon} alt="" />
      </Link>
      <Link to="/">
        <img className="weaIcon" src={WEAIcon} alt="" />
      </Link>
      <p
        className="lotPhrase"
        dangerouslySetInnerHTML={{ __html: artistData?.catchPhrase }}
      />

      <p className="howMuchWinners">{artistData?.nombreGagnants}</p>

      {!isSubscribed && (
        <>
          <div className="boxPresentation">
            <img src={artistData?.dotationImg} alt="" />
          </div>

          <div className="contentContainer">
            <h2 className="contentTitle">Le contenu</h2>
            <p
              className="lots"
              dangerouslySetInnerHTML={{ __html: artistData?.lots }}
            />

            <div id="formulaire"></div>
          </div>
        </>
      )}

      {isSubscribed && (
        <>
          <div className="contentContainer">
            <div className="priseEnCompte">
              <img src={checkIcon} alt="" />
              <p>
                Ta participation a bien été prise en compte !<br />
                <br />
                Tu seras contacté.e par email ou par téléphone dans les semaines
                à venir si tu as gagné ce lot !
              </p>
            </div>

            <div className="pushCover">
              <p>En attendant, écoute la playlist Made In K-Pop</p>
              <a href="https://lnk.to/PL-MiKPCT" target="_blank">
                <img src={PlaylistCover} alt="" />
              </a>
              <a
                href="https://lnk.to/PL-MiKPCT"
                target="_blank"
                className="listen"
              >
                écouter
              </a>
            </div>
          </div>
        </>
      )}

      <Footer />
    </main>
  );
};

export default ArtistPage;
