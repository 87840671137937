import React, { useEffect, useState } from "react";
import useScript from "../hooks/useScript";
import WEAIcon from "../assets/wea-icon.png";

import "./index.scss";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const MainPage = ({ dataDate, data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="mainPage">
      <div className="contentContainer">
        <img className="weaIcon" src={WEAIcon} alt="" />

        <h1>La semaine K-POP</h1>

        <div className="descriptionContainer">
          <p>
            Le concours est terminé. <span>Merci</span> pour votre participation
            !
          </p>
        </div>

        <ul className="linksContainer">
          {data.map((_data) => {
            return (
              <li
                key={_data.url}
                style={{
                  backgroundImage: `url(${_data.bgImg})`,
                }}
                className={`linkContainer ${
                  new Date().getTime() < _data.date.getTime() ? "blurred" : ""
                }`}
              >
                <div className="blurBG">
                  {/* <p>{_data.dateText}</p> */}
                  <h2>{_data.title}</h2>
                </div>
                {/* {new Date().getTime() >= _data.date.getTime() && (
                  <>
                    <Link to={_data.url}>participer</Link>
                  </>
                )} */}
              </li>
            );
          })}
        </ul>

        <Footer />
      </div>
    </main>
  );
};

export default MainPage;
