import React from "react";
import "./index.scss";

const Footer = () => {
  return (
    <footer className={`footer`}>
      <ul>
        <li className="footer-list-item light">
          <a
            href="http://www.warnermusic.fr/Mentions-legales"
            target="_blank"
            rel="noreferrer"
          >
            Mentions Légales
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/terms-of-use/fr/"
            target="_blank"
            rel="noreferrer"
          >
            Conditions générales d’utilisation
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/cookies-policy/fr/"
            target="_blank"
            rel="noreferrer"
          >
            Gestion des cookies
          </a>
        </li>
        <li className="footer-list-item light">
          <a
            href="https://www.wminewmedia.com/privacy/fr/"
            target="_blank"
            rel="noreferrer"
          >
            Politique de Traitement des données
          </a>
        </li>

        <li className="footer-list-item light">
          <a className="ot-sdk-show-settings">Paramétrer mes cookies</a>
        </li>
      </ul>
      <p className="credit">©2023 Warner Music France</p>
    </footer>
  );
};

export default Footer;
