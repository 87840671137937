import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import MainPage from "./pages";
import datas from "./data.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ArtistPage from "./pages/ArtistPage";

import AESPALogo from "./assets/aespa.jpg";
import AB6IXLogo from "./assets/AB6IX.jpg";
import BLOOLogo from "./assets/bloo.png";
import GOT7Logo from "./assets/got7.jpg";
import KickCafeLogo from "./assets/kick_cafe.png";
import MusicBankFestivalLogo from "./assets/music_bank_festival.png";

import AESPADota from "./assets/aespa_box.jpg";
import AB6IXDota from "./assets/ab6ix_dota.png";
import BLOODota from "./assets/bloo_box.jpg";
import GOT7Dota from "./assets/got7_box.png";
import KickCafeDota from "./assets/logo-kick-cafe.png";
import MBDota from "./assets/mb.jpg";

const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

const App = () => {
  const [data, setData] = useState([
    {
      dateText: "06.03",
      title: "AESPA",
      url: "aespa",
      nombreGagnants: "1 gagnant.e",
      catchPhrase:
        'Tente de gagner la box aespa <span>"2023 season’s greetings"</span>',
      lots: "La box • Calendrier de bureau (14 pages) • Journal à couverture rigide (120 pages) • Set de cartes postales avec enveloppe (12 pcs.) • Poster pliable (2 pcs.) • Set d'autocollants • Set d'affiches A4 (12 pcs.) • Mini brochure (16 pages) • Pack de cartes photo (8 pcs.) • 4-Cut Photo Sticker (4 pcs.) • Holiday Set (DIY Ornament (2pcs.) - Holiday  Sticker - Photo Card (4pcs.)",
      bgImg: AESPALogo,
      date: new Date("2023-02-28"),
      formId: 36521,
      dotationImg: AESPADota,
    },
    {
      dateText: "07.03",
      title: "AB6IX",
      url: "ab6ix",
      bgImg: AB6IXLogo,
      nombreGagnants: "5 gagnant.e.s",
      catchPhrase:
        'Tente de gagner la box aespa <span>"2023 season’s greetings"</span>',
      lots: "La box • Calendrier de bureau (14 pages) • Journal à couverture rigide (120 pages) • Set de cartes postales avec enveloppe (12 pcs.) • Poster pliable (2 pcs.) • Set d'autocollants • Set d'affiches A4 (12 pcs.) • Mini brochure (16 pages) • Pack de cartes photo (8 pcs.) • 4-Cut Photo Sticker (4 pcs.) • Holiday Set (DIY Ornament (2pcs.) - Holiday  Sticker - Photo Card (4pcs.)",
      date: new Date("2023-02-28"),
      formId: 36522,
      dotationImg: AB6IXDota,
    },
    {
      dateText: "08.03",
      title: "BLOO",
      url: "bloo",
      nombreGagnants: "2 gagnant.e.s",
      bgImg: BLOOLogo,
      catchPhrase:
        'Tente de gagner la box aespa <span>"2023 season’s greetings"</span>',
      lots: "La box • Calendrier de bureau (14 pages) • Journal à couverture rigide (120 pages) • Set de cartes postales avec enveloppe (12 pcs.) • Poster pliable (2 pcs.) • Set d'autocollants • Set d'affiches A4 (12 pcs.) • Mini brochure (16 pages) • Pack de cartes photo (8 pcs.) • 4-Cut Photo Sticker (4 pcs.) • Holiday Set (DIY Ornament (2pcs.) - Holiday  Sticker - Photo Card (4pcs.)",
      date: new Date("2023-02-28"),
      formId: 36523,
      dotationImg: BLOODota,
    },
    {
      dateText: "09.03",
      title: "GOT7",
      url: "got7",
      nombreGagnants: "1 gagnant.e",
      bgImg: GOT7Logo,
      catchPhrase:
        'Tente de gagner la box aespa <span>"2023 season’s greetings"</span>',
      lots: "La box • Calendrier de bureau (14 pages) • Journal à couverture rigide (120 pages) • Set de cartes postales avec enveloppe (12 pcs.) • Poster pliable (2 pcs.) • Set d'autocollants • Set d'affiches A4 (12 pcs.) • Mini brochure (16 pages) • Pack de cartes photo (8 pcs.) • 4-Cut Photo Sticker (4 pcs.) • Holiday Set (DIY Ornament (2pcs.) - Holiday  Sticker - Photo Card (4pcs.)",
      date: new Date("2023-02-28"),
      formId: 36526,
      dotationImg: GOT7Dota,
    },
    {
      dateText: "10.03",
      title: "KICK CAFE",
      url: "kick_cafe",
      nombreGagnants: "3 gagnant.e.s",
      bgImg: KickCafeLogo,
      catchPhrase:
        'Tente de gagner la box aespa <span>"2023 season’s greetings"</span>',
      lots: "La box • Calendrier de bureau (14 pages) • Journal à couverture rigide (120 pages) • Set de cartes postales avec enveloppe (12 pcs.) • Poster pliable (2 pcs.) • Set d'autocollants • Set d'affiches A4 (12 pcs.) • Mini brochure (16 pages) • Pack de cartes photo (8 pcs.) • 4-Cut Photo Sticker (4 pcs.) • Holiday Set (DIY Ornament (2pcs.) - Holiday  Sticker - Photo Card (4pcs.)",
      date: new Date("2023-02-28"),
      formId: 36524,
      dotationImg: KickCafeDota,
    },
    {
      dateText: "11.03",
      title: "MUSIC BANK FESTIVAL",
      url: "music_bank_festival",
      bgImg: MusicBankFestivalLogo,
      nombreGagnants: "1 pack de 2 places à gagner",
      catchPhrase:
        'Tente de gagner la box aespa <span>"2023 season’s greetings"</span>',
      lots: "La box • Calendrier de bureau (14 pages) • Journal à couverture rigide (120 pages) • Set de cartes postales avec enveloppe (12 pcs.) • Poster pliable (2 pcs.) • Set d'autocollants • Set d'affiches A4 (12 pcs.) • Mini brochure (16 pages) • Pack de cartes photo (8 pcs.) • 4-Cut Photo Sticker (4 pcs.) • Holiday Set (DIY Ornament (2pcs.) - Holiday  Sticker - Photo Card (4pcs.)",
      date: new Date("2023-02-28"),
      formId: 36527,
      dotationImg: MBDota,
    },
  ]);

  const [_datas] = useState(datas);

  return (
    <BrowserRouter>
      <Routes>
        {[
          "/aespa",
          "/ab6ix",
          "/bloo",
          "/got7",
          "/kick_cafe",
          "/music_bank_festival",
        ].map((path) => (
          <Route key={path} path={path} element={<ArtistPage data={data} />} />
        ))}
        <Route
          path="*"
          element={
            <MainPage
              data={data}
              dataDate={_datas?.find((data) => isToday(data.date))}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
