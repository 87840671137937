// import Image1 from "./assets/1.jpeg";
// import Image2 from "./assets/2.jpg";
// import Image3 from "./assets/3.jpg";

const datas = [
  {
    date: new Date("2023-02-24"),
    title: "Michael Jackson",
    text: "Lorem ipsum nananinanana",
    // img: Image1,
    formId: 36444,
  },
  {
    date: new Date("2023-02-28"),
    title: "Michael Jackson",
    text: "Lorem ipsum nananinanana",
    // img: Image2,
    formId: 36444,
  },
  {
    date: new Date("2023-03-01"),
    title: "Michael Jackson",
    text: "Lorem ipsum nananinanana",
    // img: Image3,
    formId: 36444,
  },
  {
    date: new Date("2023-03-02"),
    title: "Michael Jackson",
    text: "Lorem ipsum nananinanana",
    // img: Image1,
    formId: 36444,
  },
  {
    date: new Date("2023-03-03"),
    title: "Michael Jackson",
    text: "Lorem ipsum nananinanana",
    // img: Image2,
    formId: 36444,
  },
  {
    date: new Date("2023-03-04"),
    title: "Michael Jackson",
    text: "Lorem ipsum nananinanana",
    // img: Image3,
    formId: 36444,
  },
  {
    date: new Date("2023-03-05"),
    title: "Michael Jackson",
    text: "Lorem ipsum nananinanana",
    // img: Image3,
    formId: 36444,
  },
];

export default datas;
